
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ViewMixin from "@/mixins/Crud/ViewMixin";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { IpAddress } from "@/config/Modules";

const IpAddressModule = namespace(IpAddress.store);

@Component({
  components: { DefaultLayout },
  mixins: [ViewMixin],
})
export default class IpAddressView extends Vue {
  //private name = "IpAddressView";

  protected resource = IpAddress.resource;
  protected descriptionField = IpAddress.description_field;
  protected redirectRouteNameOnDeleteSuccess = "BaseDataIpAddressList";

  @IpAddressModule.Action("del")
  protected removeAction: any;

  protected onAdd(): void {
    this.$router.push({ name: "BaseDataIpAddressCreate" });
  }
}
